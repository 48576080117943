export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'

// Task Assign Start
const taskAssignPrefix = '/task-assign-tasks'
export const taskAssignList = taskAssignPrefix + '/list'
export const taskAssignStore = taskAssignPrefix + '/store'
export const taskAssignUpdate = taskAssignPrefix + '/update'
export const taskAssignVerify = taskAssignPrefix + '/verify'
export const taskAssignToggleStatus = taskAssignPrefix + '/toggle-status'
export const taskAssignDestroy = taskAssignPrefix + '/destroy'
export const getUserList = taskAssignPrefix + '/get-user-list'

// Task Report Start
const taskReportPrefix = '/task-reports'
export const taskReportStore = taskReportPrefix + '/store'
export const taskReportUpdate = taskReportPrefix + '/update'

// Task Review Notes
const taskReviewPrefix = '/task-review-notes'
export const taskReviewStore = taskReviewPrefix + '/store'
export const taskReviewUpdate = taskReviewPrefix + '/update'

// Task Calendar Starts
const taskCalendarPrefix = '/task-calendar'
export const taskCalendarList = taskCalendarPrefix + '/list'

// Task Tracker
const taskTrackerPrefix = '/task-tracker'
export const taskTrackerList = taskTrackerPrefix + '/list'

// Task Reports
const taskReportsPrefix = '/task-reports'
export const taskReportsList = taskReportsPrefix + '/all-list'

// Task Notification
const taskNotificationPrefix = 'task-notification'
export const taskNotificationList = taskNotificationPrefix + '/list'

// Daily Task Reports
const dailyTaskReportsPrefix = '/daily-task-reports'
export const dailyTaskReportsList = dailyTaskReportsPrefix + '/list'
