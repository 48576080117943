<template>
    <b-container fluid>
        <b-row v-if="!isAllowed">
            <div class="box-message col-md-12">
                <div class="message-body">
                    <p class="pb-2 pt-2"><i class="ri-alert-fill mr-2"></i>{{ $t('externalUserIrrigation.noPrivilegeFeatureMessage') }}</p>
                </div>
            </div>
        </b-row>
        <b-row v-else>
            <iq-card>
                <template v-slot:body>
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(saveTaskReport)" @reset.prevent="reset"
                                        enctype="multipart/form-data">
                                    <b-row>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider :name="$t('irrigation_task.task_completion')" vid="task_completion"
                                                                rules="required|min:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="task_completion"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('irrigation_task.task_completion') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="taskReport.complete_type_id"
                                                        id="task_status"
                                                        :options="task_status_list"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="defaultNull">
                                                                {{ $t('globalTrans.select') }}
                                                            </b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Note" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="note"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('irrigation_task.note') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                        id="note"
                                                        v-model="taskReport.note"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        placeholder="Enter something..."
                                                        rows="3"
                                                        max-rows="6"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Note (BN)" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="note_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('irrigation_task.note_bn') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                        id="note_bn"
                                                        v-model="taskReport.note_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        placeholder="Enter something..."
                                                        rows="3"
                                                        max-rows="6"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('irrigation_task.task_calendar_date') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        class="date-picker"
                                                        v-model="taskReport.task_date"
                                                        placeholder="Select Date"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Attachment" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="attachment"
                                                    slot-scope="{ errors }">

                                                    <template v-slot:label> {{ $t('globalTrans.attachment') }} <span
                                                        class="text-danger">*</span>
                                                    </template>

                                                    <b-form-file
                                                        v-model="taskReport.attachment"
                                                        :state="Boolean(taskReport.attachment)"
                                                        placeholder="Choose a file or drop it here..."
                                                        drop-placeholder="Drop file here..."
                                                    ></b-form-file>
                                                    <div class="mt-3">Selected file: {{ taskReport.attachment ? taskReport.attachment.name : '' }}</div>

                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </b-row>
    </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { taskReportStore, taskReportUpdate } from '@/modules/irrigation-scheme-service/task/api/routes'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { Common, Repository } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { core } from '@/config/pluginInit'

export default {
    mixins: [Common],
    props: {
        taskId: {
            required: true,
            type: Number
        }
    },
    components: {
    ValidationObserver,
    ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.taskId ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            taskReport: {
                complete_type_id: '',
                attachment: [],
                note: '',
                note_bn: '',
                task_date: '',
                task_id: this.taskId
            },
            defaultNull: '',
            isAllowed: true
        }
    },
    computed: {
        task_status_list () {
            return this.$store.state.IrriTask.taskReportStatusList
        }
    },
    methods: {
        async saveTaskReport () {
            const result = await Repository.create({
                data: this.taskReport,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: taskReportStore,
                updateAPI: taskReportUpdate,
                itemId: this.id,
                modalId: 'task-report'
            })

            if (result.success) {
                this.$emit('submitted')
            }
        }
    },
    created () {
        this.isAllowed = this.$store.state.ExternalUserIrrigation.pumpOptDetails
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    }
}
</script>

<style scoped>

</style>
